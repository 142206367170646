import React, { useState, useEffect } from "react";
// import { useRouter } from "next/router";
import {
  Link,
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,

} from "react-router-dom";
import imglogo from '../assets/img/logo.png';
import callimg from '../assets/img/call.png';
import { useSelector } from "react-redux";
import classNames from "classnames";
import { Helmet } from 'react-helmet';
const Header = () => {
  let navigate = useNavigate();
  const { contactData, homeData, serviceData, casestudiesData } = useSelector((state) => state.pageContents);
  console.log(contactData);
  const [activeMenus, setActiveMenus] = useState("home");
  const [location, setlocation] = useState("/");
  const handleclickmenu = (e) => {

    if (e == "home") {
      navigate(`/`);
    } else if (e == "aboutus") {
      navigate(`/aboutus`);
    } else if (e == "services") {
      navigate(`/services`);
    } else if (e == "project") {
      navigate(`/project`);
    }
    else if (e == "career") {
      navigate(`/career`);
    } else if (e == "contact") {
      navigate(`/contact`);
    }
    setlocation(window.location.pathname);
    setActiveMenus(e);
  };
  const baseUrl = "https://www.appostrophi.com"; // Replace with your domain
  React.useEffect(() => {
    var pathname = window.location.pathname;


    setlocation(window.location.pathname);
    if (window.location.pathname == "/") {
      setActiveMenus("home");

    } else if (window.location.pathname == "/aboutus") {
      setActiveMenus("aboutus");

    } else if (window.location.pathname == "/services") {
      setActiveMenus("services");

    } else if (window.location.pathname == "/project") {
      setActiveMenus("project");

    }
    else if (window.location.pathname == "/contact") {
      setActiveMenus("contact");

    }
    else if (window.location.pathname == "/career") {
      setActiveMenus("career");

    }
    else if (pathname.includes('/casestudies') == true) {
      setActiveMenus("casestudies");

    }
    else if (window.location.pathname != "/privacy-policy" && window.location.pathname != "/terms-condition" && pathname.includes('/thankyou') == false && pathname.includes('/bookademo') == false) {
      setActiveMenus("services");

    } else if (pathname.includes('/casestudies') == true) {
      setActiveMenus("casestudies");

    } else if (window.location.pathname == "/privacy-policy") {
      setActiveMenus("");

    } else if (window.location.pathname == "/terms-condition") {
      setActiveMenus("");

    }
  }, [window.location.pathname]);

  return (
    <div>
      <Helmet>
        {(location !== "/") && <link rel="canonical" href={`${baseUrl}${location}`} />}

        {activeMenus == 'home' && <title>Best K12 School Management System in New Jersey, USA | Appostrophi</title>}
        {activeMenus == 'home' && <meta name="description" content="Appostrophi drives digital transformation for K-12 schools in New Jersey, delivering solutions from gap analysis to programming that automates and modernizes workflows" />}
        {activeMenus == 'home' && <meta name="keyword" content="K12 education software,School management system,workflow automation tools,gap analysis report,efficiency audit,programming tools,new jersey department of education,doe new jersey,nj smart,security systems" />}

        {activeMenus == 'aboutus' && <title>Leveraging over 12 years of expertise to streamline workflows in k12 schools</title>}
        {activeMenus == 'aboutus' && <meta name="keyword" content="School management software,K12 education software,student information system software,school management system,education ERP solutions,academic management software" />}
        {activeMenus == 'aboutus' && <meta name="description" content="With a focus on K12 education software and school management systems, we deliver solutions with modernization" />}



        {activeMenus == 'services' && <title>Delivering our software solutions, workflow management, and audits that empower K-12 schools digitally</title>}

        {activeMenus == 'services' && <meta name="keyword" content="School management software,K12 education software,student information system software,school management system,education ERP solutions,academic management software,k 12 erp systems,best erp for educational,institutions" />}
        {activeMenus == 'services' && <meta name="description" content="From education ERP solutions to student information systems our services are customized for K12 school" />}



        {activeMenus == 'project' && <title>Our school management software projects demonstrated how K-12 schools automated through education ERP solutions.</title>}

        {activeMenus == 'project' && <meta name="keyword" content="School management software,K12 education software,student information system software,school management system,education ERP solutions,academic management software,k 12 erp systems,best erp for educational,institutions" />}
        {activeMenus == 'project' && <meta name="description" content="Our school management software projects demonstrate how K12 schools are improving efficiency with education ERP solutions." />}


        {activeMenus == 'casestudies' && <title>Our case studies with an efficient K 12 erp system transformed traditional processes into digitalization.</title>}

        {activeMenus == 'casestudies' && <meta name="keyword" content="School management software,K12 education software,student information system software,school management system,education ERP solutions,academic management software,k 12 erp systems,best erp for educational,institutions" />}
        {activeMenus == 'casestudies' && <meta name="description" content="Revealing the success stories behind our student information system software and school management software through detailed case studies." />}


        {activeMenus == 'contact' && <title>Appostrophi Contact Us</title>}

        {activeMenus == 'contact' && <meta name="keyword" content="K-12 solutions support,K-12 technology assistance,Appostrophi contact us page,Appostrophi service queries,Appostrophi contact information,Connect with K-12 education experts" />}
        {activeMenus == 'contact' && <meta name="description" content="Contact Appostrophi for inquiries about our K-12 solutions" />}


        {activeMenus == 'career' && <title>Give your career an advantage at Appostrophi</title>}

        {activeMenus == 'career' && <meta name="keyword" content="Software engineers role at Appostrophi,Backend engineers position,Software engineering positions,Software developer roles" />}
        {activeMenus == 'career' && <meta name="description" content=" Explore great career opportunities at Appostrophi, we develop school management software for district public schools in New Jersey" />}
        {
          serviceData?.services.length &&
          <>
            {
              serviceData.services.map((ser, index) => (
                <>
                  {ser.slug == 'njsmart-njdoe-data-collection-support-management' && <title>New Jersey Data Submission Support</title>}

                  {ser.slug == 'njsmart-njdoe-data-collection-support-management' && <meta name="keyword" content="NJ data submission support,NJSMART compliance services,New Jersey DOE data collection,Data management for NJ schools,NJ education compliance services,New Jersey school data management,NJ data submission assistance,NJDOE support services" />}
                  {ser.slug == 'njsmart-njdoe-data-collection-support-management' && <meta name="description" content="Appostrophi New Jersey data submission services" />}




                  {ser.slug == 'workflow-management-automation' && <title>Workflow Management Solutions for District Public Schools in New Jersey</title>}

                  {ser.slug == 'workflow-management-automation' && <meta name="keyword" content="Workflow Management Solutions for K12 schools,IT Solutions and services,K12 school workflow management,Workflow Management Services in NJ for K12 schools" />}
                  {ser.slug == 'workflow-management-automation' && <meta name="description" content="Explore Appostrophi's Workflow Management solutions for K12 Schools in New Jersey" />}



                  {ser.slug == 'gap-analysis' && <title>Gap Analysis Solutions for District Public schools in New Jersey</title>}

                  {ser.slug == 'gap-analysis' && <meta name="keyword" content="Gap Analysis Services in K12 Schools,Gap Analysis for district public school,Gap Analysis in K-12 school in NJ" />}
                  {ser.slug == 'gap-analysis' && <meta name="description" content="Optimize Operations with Our Expert Gap Analysis services" />}



                  {ser.slug == 'efficiency-audit' && <title>Efficiency Audit Services for K-12 District Public Schools in New Jersey</title>}

                  {ser.slug == 'efficiency-audit' && <meta name="keyword" content="K-12 School Efficiency Audits,District Public SchoolsEfficiency Audits,Efficiency Audit Services in K-12 schools,Efficiency Evaluation in K-12 schools" />}
                  {ser.slug == 'efficiency-audit' && <meta name="description" content="Appostrophi's customized Efficiency Audits designed for K-12 District Public Schools in New Jersey." />}



                  {ser.slug == 'programming' && <title> IT Services for K-12 District Public Schools Schools in New Jersey</title>}

                  {ser.slug == 'programming' && <meta name="keyword" content="Bespoke software solutions for K 12 schools in NJ,Custom Programming Services for K-12 schools,K-12 Software Solutions and Products,Dynamic Web App Development for Schools,Programming Services for K-12 Education,K-12 Programming Experts,K-12 Digital Transformation Services,IT Infrastructure for K-12 schools" />}
                  {ser.slug == 'programming' && <meta name="description" content="Expert software development services for K-12 Schools in New Jersey" />}

                </>
              ))
            }

            {
              casestudiesData?.casestudies.length &&
              <>
                {
                  casestudiesData.casestudies.map((ser, index) => (
                    <>
                      {ser.slug == 'e-form-approval-routing-system' && <title>School ERP E-Form Approval and Routing System</title>}

                      {ser.slug == 'e-form-approval-routing-system' && <meta name="keyword" content="School ERP,K12 E-Form Approval System,E-Form Approval Case Study for K12 schools,E-Form Solutions for K-12 District Public Schools,K-12 E-Form Submission Automation" />}
                      {ser.slug == 'e-form-approval-routing-system' && <meta name="description" content="Explore how Appostrophi's E-Form Approval and Routing System transformed largest K-12 district public schools in New Jersey" />}




                      {ser.slug == 'workshop-management-system' && <title>Workshop Management System for K12 District Public Schools in New Jersey</title>}

                      {ser.slug == 'workshop-management-system' && <meta name="keyword" content="K-12 schools Workshop Management System,Centralized Workshop Management for K12 schools,Automated Workshop Management for K12 schools" />}
                      {ser.slug == 'workshop-management-system' && <meta name="description" content="Discover innovative Workshop Management System implementation in K-12 schools" />}



                      {ser.slug == 'facility-rental-management-system' && <title>Facility Rental Management System for K12 District Public Schools in New Jersey</title>}

                      {ser.slug == 'facility-rental-management-system' && <meta name="keyword" content="Facility Rental Management System for K-12 schools,Asset Management for K12 School,K-12 schools Facility Rental Management Solutions,Automated Facility Rentals for K12 schools,Facility Rental Management Automation for K12 Schools,Case Studies for Facility Rental Management System" />}
                      {ser.slug == 'facility-rental-management-system' && <meta name="description" content="Find out how Appostrophi transformed rental management processes in a largest district public schools in New Jersey " />}



                      {ser.slug == 'student-transcript-application' && <title>Student Transcript Application for K12 District Public Schools in New Jersey</title>}

                      {ser.slug == 'student-transcript-application' && <meta name="keyword" content="Student Transcript Application for K12 schools,K-12 Academic Record Management,Student Record Keeping for K12 schools,Student record Archival for K12 School,K-12 schools student Record Management" />}
                      {ser.slug == 'student-transcript-application' && <meta name="description" content="Discover how Appostrophi's Student Transcript Application improved data preservation and access for student records in K-12 education" />}




                    </>
                  ))
                }
              </>

            }
          </>

        }
      </Helmet>
      <div className='main-sec-header'>
        <div className='container'>
          <div className='row'>

            <nav class="navbar navbar-expand-lg navbar-light ">
              <Link class="navbar-brand" to="/">
                <img src={imglogo} alt='logo' />
              </Link>
              <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mb-2 mb-lg-0">
                  <li class="nav-item nav-new-menu" onClick={(e) => handleclickmenu("home")}>
                    <Link to="/" className={`nav-link ${activeMenus == 'home' && 'active'}`} ><i class="fa-solid fa-house"></i></Link>

                  </li>
                  <li class="nav-item nav-new-menu" onClick={(e) => handleclickmenu("aboutus")}>
                    <Link to="/aboutus" className={`nav-link nav-new ${activeMenus == 'aboutus' && 'active'}`} >About Us</Link>


                  </li>
                  <li class="nav-item nav-new-menu" onClick={(e) => handleclickmenu("services")} >
                    <Link to="/services" className={`nav-link nav-new ${activeMenus == 'services' && 'active'}`} >Services</Link>

                  </li>




                  <li class="nav-item nav-new-menu" onClick={(e) => handleclickmenu("project")}>
                    <Link to="/project" className={`nav-link nav-new ${activeMenus == 'project' && 'active'}`}>Projects</Link></li>

                  <li class="nav-item nav-new-menu" onClick={(e) => handleclickmenu("casestudies")}>
                    {
                      homeData?.projects.length &&
                      <Link to={`/casestudies/${homeData.projects[0].slug}`} className={`nav-link nav-new ${activeMenus == 'casestudies' && 'active'}`}>Case Studies</Link>
                    }

                  </li>
                  <li class="nav-item nav-new-menu" onClick={(e) => handleclickmenu("career")}>
                    <Link to="/career" className={`nav-link nav-new ${activeMenus == 'career' && 'active'}`}>Careers</Link></li>
                  <li class="nav-item nav-new-menu" onClick={(e) => handleclickmenu("contact")}>
                    {/* <Link to="/contact" className={`nav-link nav-last ${activeMenus == 'contact' && 'active'}`}>Contact Us</Link></li> */}
                    <Link to="/contact" className={`nav-link nav-new  ${activeMenus == 'contact' && 'active'}`}>Contact Us</Link></li>







                </ul>
                {/* <form class="d-flex">
                <a class="btn btn-call mob-no" href={`tel:${contactData.contactpage.phone}`}>
                  <img src={callimg} className='imgwhite' />


                  {
                    contactData?.contactpage &&
                    contactData.contactpage.phone
                  }</a>
              </form> */}
              </div>
            </nav>

          </div>

        </div>
      </div>
    </div>

  )
}
export default Header;
